import React from "react";
import PageLayout from "../components/layout/pageLayout";

const ViewChapterPage = () => {
  return (
    <PageLayout
      title="View Chapters - ATC Africa"
      metaDescription="View ATC Africa Chapters"
      href="https://atcafrica.com.ng"
    >
      <section className="flex flex-col items-center justify-center py-8 px-6 lg:px-10 w-full max-w-[900px] mx-auto relative">
        <h1 className="capitalize  text-primaryColor md:text-[24px] font-[600] text-center py-2 px-2 mx-auto border-b-[4px] border-primaryColor w-max">
          chapters across africa
        </h1>
        <div className="my-8 flex flex-row flex-wrap gap-8 w-full justify-center text-primaryColor md:text-[18px]">
          <p className="w-[250px]">ATC Africa South Africa</p>
          <p className="w-[250px]">ATC Zimbabwe</p>
          <p className="w-[250px]">ATC Nigeria</p>
          <p className="w-[250px]">ATC Abuja Chapter</p>
          <p className="w-[250px]">ATC Lagos Chapter</p>
          <p className="w-[250px]">ATC Ogun Chapter</p>
          <p className="w-[250px]">ATC Kaduna Chapter</p>
          <p className="w-[250px]">ATC Bauchi Chapter</p>
          <p className="w-[250px]">ATC Ondo Chapter</p>
          <p className="w-[250px]">ATC Oyo Chapter</p>
          <p className="w-[250px]">ATC Imo Chapter</p>
          <p className="w-[250px]">ATC Rivers Chapter</p>
          <p className="w-[250px]">ATC Kwara Chapter</p>
          <p className="w-[250px]">ATC Ekiti Chapter</p>
          <p className="w-[250px]">ATC Osun Chapter</p>
        </div>
      </section>
    </PageLayout>
  );
};

export default ViewChapterPage;
