import React from "react";
import PageLayout from "../components/layout/pageLayout";

const ContactPage = () => {
  return (
    <PageLayout
      title="Contact Us - ATC Africa"
      metaDescription="Get in touch with us at ATC Africa"
      href="https://atcafrica.com.ng"
    >
      <section className="bg-primaryColor flex flex-col px-6 py-8 sm:py-16 md:px-10">
        <div className="w-full max-w-[1440px] flex flex-col-reverse gap-6 mx-auto sm:grid grid-cols-[1fr_1fr] justify-center md:gap-0">
          <div className="w-full lg:w-[80%] mx-auto  flex flex-col sm:justify-center text-white font-[500] sm:px-4">
            <h1 className="text-[24px] sm:text-[44px] lg:text-[64px]">
              Contact Us
            </h1>
            <p className="sm:text-[24px]">
              Looking to connect? <br />
              Reach out to us at ATC Africa.
            </p>

            <div className="mt-6 flex flex-col gap-6">
              <div>
                <p>Email Address:</p>
                <a className="block" href="mailto:hello@atcafrica.com.ng">
                  Hello@atcafrica.com.ng
                </a>
                <a className="block" href="mailto:partnership@atcafrica.com.ng">
                  Partnership@atcafrica.com.ng
                </a>
              </div>
              <div>
                <p>Telephone:</p>
                <a className="block" href="tel:+2348123051357">
                  +2348123051357
                </a>
                <a className="block" href="tel:+2348140799515">
                  +2348140799515
                </a>
              </div>
            </div>
          </div>
          <div className="">
            <form className="px-4 lg:px-6 lg:w-[70%] mx-auto py-4 bg-white rounded-[8px] flex flex-col gap-6">
              <div>
                <p className="text-primaryColor">
                  We’d love to hear from you!
                  <br />
                  Let’s get in touch
                </p>
              </div>
              <input
                type="text"
                placeholder="Full Name"
                name=""
                id=""
                required
                className="py-2 px-2 border-primaryColor border-[1px] outline-none rounded-[8px]"
              />
              <input
                type="email"
                placeholder="Email Address"
                name=""
                id=""
                required
                className="py-2 px-2 border-primaryColor border-[1px] outline-none rounded-[8px]"
              />

              <input
                type="text"
                placeholder="State/City"
                name=""
                id=""
                required
                className="py-2 px-2 border-primaryColor border-[1px] outline-none rounded-[8px]"
              />
              <textarea
                name=""
                id=""
                rows="3"
                required
                placeholder="Write your message here..."
                className="py-2 px-2 border-primaryColor border-[1px] outline-none rounded-[8px] resize-none"
              />
              <button className="text-white bg-primaryColor hover:bg-hoverPrimary active:bg-activePrimary  py-2 px-4 rounded-[8px] ">
                Send Message
              </button>
            </form>
          </div>
        </div>
      </section>
    </PageLayout>
  );
};

export default ContactPage;
