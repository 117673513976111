import PageLayout from "../components/layout/pageLayout";

const AboutPage = () => {
  return (
    <PageLayout>
      <div className="bg-state-lead bg-center bg-no-repeat bg-cover bg-primaryColor bg-blend-soft-light h-[300px] md:h-[400px] lg:h-[500px] w-full flex justify-center items-center px-6 lg:px-10">
        <h1 className="text-white text-center font-[700] text-[50px] md:text-[70px]">
          About Us
        </h1>
      </div>
      <section className="flex flex-col gap-8 px-6 lg:px-10 pt-6 pb-16 w-full max-w-[900px] mx-auto">
        <div>
          <div className="flex flex-col gap-6 mt-6">
            <p className="leading-7">
              We are more than just a community; we are a movement. We are the
              change-makers, the dreamers, and the visionaries who are
              passionate about shaping a brighter technological future for our
              continent.
            </p>
            <p className="leading-7">
              We are coders, designers, entrepreneurs, and innovators from all
              walks of Tech united by a common purpose: to transform the
              technological landscape and empower our communities across africa
            </p>
          </div>
        </div>
        <div>
          <div className="flex items-center justify-end">
            <h2 className="text-right md:text-[24px] font-[600] py-2 px-2  border-b-[4px] border-primaryColor w-max">
              Here is What Drives Us
            </h2>
          </div>
          <div className="flex flex-col gap-6 mt-6">
            <p className="leading-7">
              <span className="font-[600]">A Passion for Progress: </span>We
              believe technology has the power to tackle some of our
              continent&apos;s biggest challenges. From building sustainable
              solutions to fostering economic growth and social progress,
              we&apos;re driven by a desire to make a real difference.
            </p>
            <p className="leading-7">
              <span className="font-[600]">A Spirit of Collaboration: </span>
              We understand that innovation thrives in an environment of shared
              ideas and open exchange. We foster a collaborative spirit, where
              individuals from diverse backgrounds come together to learn from
              each other and push the boundaries of what&apos;s possible.
            </p>
            <p className="leading-7">
              <span className="font-[600]">A Commitment to Inclusivity: </span>
              We believe that everyone deserves a seat at the table. We actively
              work to create a space where all voices are heard and all talents
              are valued. Our goal is to build a future where technology
              empowers everyone, not just the privileged few.
            </p>
          </div>
        </div>
        <div>
          <h2 className="md:text-[24px] font-[600] py-2 px-2  border-b-[4px] border-primaryColor w-max">
            What We Do
          </h2>
          <div className="flex flex-col gap-6 mt-6">
            <p className="leading-7">
              <span className="font-[600]">Building Solutions: </span>
              We don&apos;t just dream, we take action. We develop innovative
              technological solutions that address the specific needs of our
              continent
            </p>
            <p className="leading-7">
              <span className="font-[600]">Sharing Knowledge: </span>
              We believe that knowledge is power. We host workshops, hackathons,
              and mentorship programs to share our members and empower them to
              become tech leaders both in soft skills and Technical part
            </p>
          </div>
        </div>
      </section>
    </PageLayout>
  );
};

export default AboutPage;
