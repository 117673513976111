import React from "react";

const EventCard = ({ image, title, text }) => {
  return (
    <div className="w-full max-w-[340px]">
      <div className="relative z-[1]">
        <img src={image} alt="program-pic" className="w-[340px] h-[224px]" />
      </div>
      <div className="mx-[14px]">
        <div className="bg-white relative rounded-[8px] px-4 shadow-[0px_9px_20px_0px_#00000029] min-h-[191px] mx-auto py-3 z-[2] -mt-10">
          <button className="text-[20px] lg:text-[24px] text-primaryColor font-[600] cursor-pointer">
            {title}
          </button>
          <p className="mt-2 md:leading-[24px] text-[14px] lg:text-4">{text}</p>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
