import "./App.css";
import AppRouter from "./router/approuter";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HelmetProvider } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CtaProvider from "./context/ctaContext";

const helmetContext = {};
function App() {
  return (
    <HelmetProvider context={helmetContext}>
      <CtaProvider>
        <AppRouter />
        <ToastContainer />
      </CtaProvider>
    </HelmetProvider>
  );
}

export default App;
