import React, { createContext, useContext, useEffect, useState } from "react";

const CtaContext = createContext(null);

const CtaProvider = ({ children }) => {
  const [status, setStatus] = useState(false);
  const [isBus, setIsBus] = useState(false);
  useEffect(() => {
    setStatus(true);
  }, []);
  const handleStatus = (stat) => {
    setStatus(stat);
    setIsBus(true);
  };

  const handleBus = (stat) => {
    setIsBus(stat);
  };
  return (
    <CtaContext.Provider value={{ status, isBus, handleStatus, handleBus }}>
      {children}
    </CtaContext.Provider>
  );
};

export default CtaProvider;

const useCtaContext = () => {
  return useContext(CtaContext);
};

export { useCtaContext };
