import React from "react";

const TestimonialCard = ({ text, name, role, image }) => {
  return (
    <div className="flex flex-col justify-between w-[292px] h-[333px] md:w-[432px] md shadow-[0px_9px_12px_0px_#00000024] rounded-[16px] px-[16px] py-[18px]">
      <img
        src="/icons/quote.svg"
        alt=""
        className="block mx-auto w-[48px] h-[39px] md:w-[69px] md:h-[58px]"
      />
      <p className="flex-grow my-4 text-[14px] md:text-[15px]">{text}</p>
      <div className="flex items-center gap-3">
        <img src={image} alt="" />
        <div>
          <p className="text-[14px] md:text-[16px] font-[600] text-primaryColor">
            {name}
          </p>
          <p className="text-[12px] md:text-[14px]">{role}</p>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
