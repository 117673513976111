import React from "react";
import { Link } from "react-router-dom";

const BlogCard = ({
  cardImage,
  type,
  headline,
  text,
  author,
  authorName,
  date,
  typeColor,
  bgTypeColor,
  readTime,
  className,
  blogAddress,
}) => {
  return (
    <Link
      to={blogAddress}
      className="flex flex-col justify-between w-full rounded-[5px] "
    >
      <div className="overflow-clip h-[190px] rounded-[5px] duration-300">
        <div className="block h-[190px] rounded-[5px] relative hover:scale-[1.025]">
          <img
            src={cardImage}
            alt="blog-pic"
            className="w-[100%] h-[190px] object-cover rounded-t-[5px]"
          />
          <div className="absolute inset-0 bg-gray-500 bg-opacity-50 opacity-0 hover:opacity-100 flex items-center justify-center transition-opacity duration-300"></div>
        </div>
      </div>

      <div className="flex-grow py-4 flex justify-between flex-col gap-4">
        <div className="flex flex-grow flex-col">
          <p
            style={{
              backgroundColor: `#${bgTypeColor}`,
              color: `#${typeColor}`,
            }}
            className={`text-[12px] uppercase font-[500] w-max px-[12px] py-[2px] rounded-[4px]`}
          >
            {type}
          </p>
          <p className="mt-2 flex-grow font-[500] text-primaryColor">
            {headline}
          </p>
          {/* <p className="text-[14px] text-gray-500">{text}</p> */}
        </div>

        <div className="flex justify-between items-center">
          <div className="flex items-center gap-2">
            <img src={author} alt="author-pic" className="size-[40px]" />
            <div>
              <p className="text-[14px]">{authorName}</p>
              <p className="text-[12px] text-[#808080]">{date}</p>
            </div>
          </div>
          {/* <p className="text-[12px] text-[#808080]">{readTime}</p> */}
        </div>
      </div>
    </Link>
  );
};

export default BlogCard;
