import { Link } from "react-router-dom";
import BlogCard from "../components/card/blogCard";
import PageLayout from "../components/layout/pageLayout";
import { useEffect, useState } from "react";

const Blog = () => {
  const [data, setData] = useState(null);
  const [loading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getBlog = async () => {
      try {
        setError(null);
        setIsLoading(true);
        const req = await fetch("/data/blogposts.json");
        const res = await req.json();
        setData(res);
      } catch (error) {
        console.log(error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };
    getBlog();
  }, []);

  return (
    <PageLayout
      title={"Blog - ATC Africa"}
      metaDescription={
        "Welcome to ATC Blog. Keep updated with news about ATC campaign worldwide"
      }
      to="https://atcafrica.com.ng"
    >
      <>
        {loading ? (
          <div className="h-[300px] flex flex-col items-center justify-center">
            <p className="text-[20px] text-gray-700">Loading...</p>
          </div>
        ) : data ? (
          <section className="flex flex-col w-full max-w-[1440px] px-6 lg:px-10 mx-auto">
            <div className="mt-8 w-full max-w-[1200px] mx-auto py-8">
              <div className="flex flex-col md:flex-row justify-between gap-12">
                <Link
                  to={`/blog/${data[0].slug}`}
                  className="self-start w-full md:w-[65%]"
                >
                  <div className="overflow-clip md:h-[450px] rounded-[5px] duration-300">
                    <div className="md:h-[450px] overflow-clip rounded-[5px] relative hover:scale-[1.05]">
                      <img
                        src={data[0].cardImage}
                        alt=""
                        className=" object-cover md:h-[450px] w-full "
                      />
                      <div className="absolute inset-0 bg-gray-500 bg-opacity-50 opacity-0 hover:opacity-100 flex items-center justify-center transition-opacity duration-300"></div>
                    </div>
                  </div>
                  <div className="flex-grow py-6 flex justify-between flex-col gap-4">
                    <div className="flex flex-col gap-1">
                      <p
                        style={{
                          backgroundColor: `#${data[0].bgTypeColor}`,
                          color: `#${data[0].typeColor}`,
                        }}
                        className={`uppercase text-[12px] font-[500] w-max px-[12px] py-[2px] rounded-[4px]`}
                      >
                        {data[0].type}
                      </p>
                      <p className="mt-2 flex-grow text-[18px] md:text-[30px] font-[600] text-primaryColor">
                        {data[0].name}
                      </p>
                      <div className="mt-4 flex items-center gap-4">
                        <img src={data[0].author} className="w-[40px]" />
                        <div>
                          <p className="text-[14px]">{data[0].authorName}</p>
                          <p className="text-[12px] text-[#808080]">
                            {data[0].date}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
                <div className="w-full md:w-[33%]">
                  {/* <form className="hidden md:block mb-8">
                <Search />
              </form> */}
                  <h2 className="text-[14px] uppercase font-[500] text-gray-500">
                    Latest
                  </h2>
                  <p className="font-[900] text-[18px]">Trending Articles</p>
                  <div className="flex flex-col gap-4 py-4">
                    {data.map((single, index) => {
                      if (index != 0 && index <= 4)
                        return (
                          <Link
                            key={index}
                            className="flex gap-4"
                            to={`/blog/${single.slug}`}
                          >
                            <div className="h-[70px] overflow-clip rounded-[5px]">
                              <img
                                src={single.cardImage}
                                className="max-w-[80px] h-[70px] object-cover rounded-[5px] hover:scale-[1.05] duration-75"
                              />
                            </div>
                            <div className="w-full border-b-[1px] pb-4">
                              <p className="uppercase text-[12px] text-primaryColor">
                                {single.type}
                              </p>
                              <p className="">{single.name}</p>
                              <p className="mt-4 text-[14px]">
                                <span className="text-[12px] text-gray-500">
                                  by
                                </span>{" "}
                                {single.authorName}
                              </p>
                            </div>
                          </Link>
                        );
                    })}
                  </div>
                  {/* <form className="md:hidden flex flex-col gap-6">
                <h2 className="text-[25px] font-[500] border-gray-500 ">
                  Search
                </h2>
                <Search />
              </form> */}
                </div>
              </div>
              <div className="my-12">
                <h2 className="text-[25px] font-[900] border-gray-500 pb-2">
                  Must Read
                </h2>
                <div className="grid sm:grid-cols-2 md:grid-cols-3 justify-center sm:justify-between gap-x-8 gap-y-12 my-4">
                  {data.map((single, index) => {
                    return (
                      <BlogCard
                        key={index}
                        blogAddress={`/blog/${single.slug}`}
                        cardImage={single.cardImage}
                        type={single.type}
                        author={single.author}
                        authorName={single.authorName}
                        date={single.date}
                        headline={single.name}
                        text={`${single.description.slice(0, 130)}...`}
                        bgTypeColor={single.bgTypeColor}
                        typeColor={single.typeColor}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </section>
        ) : (
          <div className="h-[300px] flex flex-col items-center justify-center">
            <p className=" text-gray-700"> {error}</p>
          </div>
        )}
      </>
    </PageLayout>
  );
};

export default Blog;
