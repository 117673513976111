import React from "react";

const MissionCard = ({ image, number, text, show = true }) => {
  return (
    <div className="shadow-[0px_9px_12px_0px_#00000024] w-[102px] md:w-[129px] h-[173px] lg:h-[207px] py-[10px] lg:py-[20px] px-[10px] flex flex-col gap-4 text-center items-center rounded-br-[36px] bg-mission-gradient">
      <img src={image} alt="mission-pic" className="size-[48px]" />
      <div>
        <p className="text-[24px] lg:text-[32px] text-primaryColor font-[600]">
          {number}
          {show && <span className="text-yellow-500">+</span>}
        </p>
        <p className="text-[14px] lg:text-4">{text}</p>
      </div>
    </div>
  );
};

export default MissionCard;
