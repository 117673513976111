import React from "react";
import PageLayout from "../components/layout/pageLayout";

const DirectorsPage = () => {
  return (
    <PageLayout
      title="Directors - ATC Africa"
      metaDescription="View ATC Africa board of directors"
      href="https://atcafrica.com.ng"
    >
      <section className="h-[500px] flex flex-col items-center justify-center px-6 lg:px-10 w-full max-w-[1440px] mx-auto relative">
        <img
          animate
          src="/icons/triangle.png"
          alt=""
          className="absolute -z-[99] top-12 left-[10%] md:left-[20%]"
        />
        <img
          src="/icons/triangle.png"
          alt=""
          className="absolute -z-[99] top-16 right-[10%] md:left-[70%]"
        />
        <img
          src="/icons/triangle-empty-yellow.png"
          alt=""
          className="absolute -z-[99] bottom-12 left-10 md:left-20"
        />
        <img
          src="/icons/circle-small.png"
          alt=""
          className="absolute -z-[99] top-12 right-[50%]"
        />
        <img
          src="/icons/circle.png"
          alt=""
          className="absolute -z-[99] top-[200px] right-14 md:right-28"
        />
        <img
          src="/icons/curve-colored.png"
          alt=""
          className="absolute -z-[99] top-[300px] md:left-[20%]"
        />
        <img
          src="/icons/triangle-empty.png"
          alt=""
          className="absolute -z-[99] top-[300px] right-[10%]  md:right-[35%]"
        />
        <img
          src="/icons/circle.png"
          alt=""
          className="absolute -z-[99] top-44"
        />

        <div className="w-full max-w-[500px] text-center">
          <p className="text-[32px] mb-4 md:text-[48px] font-[600] md:leading-[64px] text-gray-700">
            COMING SOON
          </p>
          <p className="text-gray-500">
            This page is still under construction. Please bear with us.{" "}
          </p>
        </div>
      </section>
    </PageLayout>
  );
};

export default DirectorsPage;
